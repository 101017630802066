<template>
  <v-container class="mb-12" v-if="group && team">
    <v-card subheader elevation="0">
      <v-card-title class="px-2 pb-0 pt-6 headline">
        Groupe {{ group.info.group }}
      </v-card-title>
      <v-card-text class="px-2 pt-2">
        <v-row v-for="(team, i) in group.teams" :key="i" class="ma-0">
          <span v-if="teamId && teamId == team.id"
            ><strong :class="setColor(team.id)">{{ team.name }}</strong></span
          ><span :class="setColor(team.id)" v-else>{{ team.name }}</span>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>

    <v-expansion-panels v-model="panel" flat accordion>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-2 headline"
          >Horaires des matchs</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-card-text class="mx-0 pt-0 px-0">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="px-1">T</th>
                  <th class="px-1">Equipe 1</th>
                  <th class="px-1">Equipe 2</th>
                  <th class="px-1">Heure</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(match, k) in group.schedules"
                  :key="k"
                  :class="setColorMatch(match.team1_id, match.team2_id)"
                >
                  <td class="px-1">{{ match.field }}</td>
                  <td class="px-1">
                    <span v-if="teamId && teamId == match.team1_id"
                      ><strong>{{ match.team1 }}</strong></span
                    ><span v-else>{{ match.team1 }}</span>
                  </td>
                  <td class="px-1">
                    <span v-if="teamId && teamId == match.team2_id"
                      ><strong>{{ match.team2 }}</strong></span
                    ><span v-else>{{ match.team2 }}</span>
                  </td>
                  <td class="px-1">{{ match.time.substring(0, 5) }}</td>
                  <td class="px-1" v-if="$store.getters.getIsReferee">
                    <v-btn
                      icon
                      color="indigo"
                      :to="'/score/' + match.id_match"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody></v-simple-table
            >
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="px-1 headline"
          >Résultats</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-card-text class="mx-0 px-0" v-if="score">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="px-1">Equipe 1</th>
                  <th class="px-1">Equipe 2</th>
                  <th class="px-1 text-right">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(match, k) in score.schedule"
                  :key="k"
                  :class="setColorMatch(match.team1_id, match.team2_id)"
                >
                  <td class="px-1">
                    <span v-if="teamId && teamId == match.team1_id"
                      ><strong>{{ team.name }}</strong></span
                    ><span v-else>{{ match.team1 }}</span>
                  </td>

                  <td class="px-1">
                    <span v-if="teamId && teamId == match.team2_id"
                      ><strong>{{ match.team2 }}</strong></span
                    ><span v-else>{{ match.team2 }}</span>
                  </td>
                  <td
                    class="px-1 text-right"
                    v-html="
                      match.score1 !== '' ? match.score1 + '-' + match.score2 : ''
                    "
                  ></td>
                </tr></tbody
            ></v-simple-table>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="score">
        <v-expansion-panel-header class="px-2 headline"
          >Classement</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-card-text class="mx-0 px-0">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="px-1">Rang</th>
                  <th class="px-1">Equipe</th>
                  <th class="px-1">J</th>
                  <th class="px-1">G</th>
                  <th class="px-1">N</th>
                  <th class="px-1">P</th>
                  <th class="px-1 text-right">Pts</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(pos, k) in score.ranking"
                  :key="k"
                  :class="setColor(pos.team_id)"
                >
                  <td class="px-1" v-html="k + 1"></td>
                  <td class="px-1">
                    <span>{{ pos.team }}</span>
                  </td>
                  <td class="px-1">{{ pos.played }}</td>
                  <td class="px-1">{{ pos.won }}</td>
                  <td class="px-1">{{ pos.drawn }}</td>
                  <td class="px-1">{{ pos.lost }}</td>
                  <td class="px-1 text-right">
                    <strong>{{ pos.points }}</strong>
                  </td>
                </tr>
              </tbody></v-simple-table
            >
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TimetablePerTeam',
  props: ['groupId', 'teamId'],

  data: () => ({
    categories: [],
    selectedCategory: 0,
    group: null,
    score: null,
    panel: 0,
  }),
  methods: {
    setColor: function (team) {
      if (team == this.team1 || team == this.team2) {
        return 'yellow lighten-3'
      }
    },
    setColorMatch: function (team1, team2) {
      if (team1 == this.team1 || team1 == this.team2) {
        return 'yellow lighten-3'
      }
      if (team2 == this.team1 || team2 == this.team2) {
        return 'yellow lighten-3'
      }
    },
  },
  async mounted() {
    this.team1 = this.$store.getters.getTeam1
    this.team2 = this.$store.getters.getTeam2

    axios.defaults.baseURL = 'https://sekulictest.e-tournament.ch/api/'
    const response = await axios.get(
      'json.php?get=groups-schedule-bygroup&idg=' + this.groupId
    )

    this.group = response.data

    for (const item of this.group.teams) {
      if (item.id == this.teamId) {
        this.team = item
      }
    }

    this.$emit('set-menu', {
      title: 'Team ' + this.team.name,
      back: '/teams',
      icon: 'mdi-close',
    })

    const response2 = await axios.get(
      '/json.php?get=ranking-bygroup&idg=' +
      this.group.info.id
    )
    this.score = null;
    if(response2.data.groups !== undefined ){
      for (const score of response2.data.groups) {
        if (score.info.id == this.group.info.id) {
          this.score = score
        }
      }
    }
  },
}
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
