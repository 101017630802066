<template>
  <v-container class="mb-12" v-if="finales">
    <v-expansion-panels v-model="panel" flat accordion>
      <v-expansion-panel
        v-for="(finale, k) in finales.stage"
        :key="k"
        :disabled="finale.matchs[0].team1 == ''"
      >
        <v-expansion-panel-header class="px-1 headline">{{
          finale.info.name
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card tile subheader class="mt-0" elevation="0">
            <v-card-text class="mx-0 pt-0 px-0">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="px-1">T</th>
                    <th class="px-1">Equipe 1</th>
                    <th class="px-1">Equipe 2</th>
                    <th class="px-1">Heure</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(match, k) in finale.matchs"
                    :key="k"
                    :class="setColorMatch(match.team1_id, match.team2_id)"
                  >
                    <td class="px-1">{{ match.field }}</td>
                    <td class="px-1">
                      {{ match.team1 ? match.team1 : '-' }}
                    </td>

                    <td class="px-1">
                      {{ match.team2 ? match.team2 : '-' }}
                    </td>
                    <td class="px-1">
                      {{ match.team1 ? match.time.substring(0, 5) : '-' }}
                    </td>
                  </tr>
                </tbody></v-simple-table
              >
            </v-card-text>
            <v-card-actions> </v-card-actions>
          </v-card> </v-expansion-panel-content></v-expansion-panel
    ></v-expansion-panels>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Groups',
  data: () => ({
    categories: [],
    selectedCategory: 0,
    finales: [],
    panel: 0,
  }),
  methods: {
    setColor: function (team) {
      if (team == this.team1 || team == this.team2) {
        return 'yellow lighten-3'
      }
    },
    setColorMatch: function (team1, team2) {
      if (team1 == this.team1 || team1 == this.team2) {
        return 'yellow lighten-3'
      }
      if (team2 == this.team1 || team2 == this.team2) {
        return 'yellow lighten-3'
      }
    },
  },
  async mounted() {
    this.team1 = this.$store.getters.getTeam1
    this.team2 = this.$store.getters.getTeam2

    const cat = this.$store.getters.getCategory
    axios.defaults.baseURL = 'https://sekulictest.e-tournament.ch/api/'
    const response = await axios.get('json.php?get=final-bycategory&idc=' + cat)
    this.finales = response.data
    console.log(this.finales)
  },
}
</script>

<style lang="scss">
.grouplink {
  text-decoration: none;
  color: #000 !important;
}
.btnlower {
  text-transform: none;
}
.v-card {
  border: none !important;
}
</style>
