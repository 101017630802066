<template>
  <v-container class="mb-12">
    <v-select
    v-model="field"
    :items="fields"
    item-text="name"
    item-value="id"
    return-object
    label="Choisissez le terrain"
    persistent-hint
    single-line
    v-on:change="changeField()"
  ></v-select>
<!--  -->
  <v-simple-table>
    <thead>
       <tr>
        <th class="px-1">Equipe 1</th>
        <th class="px-1">Equipe 2</th>
        <th class="px-1">Heure</th>
        <th class="px-1"></th>
      </tr>
    </thead>
    <tbody v-if="field">
      <tr v-for="(match, k) in field.matches"
        :key="k"
      >
        <td class="px-1">
          <span>{{ match.team1 }}</span>
        </td>
        <td class="px-1">
            <span>{{ match.team2 }}</span>
        </td>
        <td class="px-1">{{ match.time.substring(0, 5) }}</td>
        <td class="px-1" v-if="$store.getters.getIsReferee">
          <v-btn
            icon
            color="indigo"
            :to="'/score/' + match.match_id"
            >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </td>
      </tr>
    </tbody>
    </v-simple-table>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Fields',
  data: () => ({
    categories: [],
    selectedCategory: 0,
    field: null,
    fields: []
  }),
  async mounted() {
    this.myTeam = this.$store.getters.getTeamId
    const response = await axios.get(
      'https://sekulictest.e-tournament.ch/admin/api/v2/' + this.$store.getters.getTournoi + '/' + this.$store.getters.getCategory + '/m/fields'
    )

    for (let i in response.data.fields) {
      const myField = {
          id: i,
          name: 'Terrain ' + i,
          matches: response.data.fields[i]
        }
      this.fields.push(
        myField
      )

      console.log(this.$store.getters.getField)
      if (myField.id == this.$store.getters.getField ){
        alert('a')
        this.field = myField
      }
    }

    // const fieldId = this.selectedField = this.$store.getters.getField
    // if(this.selectedField){
    //   this.field =
    // }

    this.$emit('set-menu', {
      title: 'Terrains',
      link: '/',
      icon: 'mdi-close',
    })
  },
  methods:{
    changeField: function (){
      console.log(this.field.id)
      this.$store.dispatch('changeField', this.field.id)
    },
    setColorMatch: function (team1, team2) {
      if (team1 == this.team1 || team1 == this.team2) {
        return 'yellow lighten-3'
      }
      if (team2 == this.team1 || team2 == this.team2) {
        return 'yellow lighten-3'
      }
    },
  },
  create() {},
}
</script>

<style lang="scss">
.grouplink {
  text-decoration: none;
  color: #000 !important;
}
.btnlower {
  text-transform: none;
}
.v-card.v-card--link {
  border: 1px #eeeeee solid !important;
}
</style>
