<template>
  <v-app max-width="600">
    <v-main class="main">
      <TopBar :menu="menu" />
      <router-view @set-menu="setMenu" />
    </v-main>
    <Menu />
  </v-app>
</template>

<script>
import TopBar from './components/TopBar.vue'
import Menu from './components/Menu.vue'
import axios from 'axios'

export default {
  name: 'App',
  data: () => ({
    menu: { title: 'Sekulic 2022', link: '/settings', icon: 'mdi-menu' },
  }),
  components: {
    TopBar,
    Menu,
  },
  async mounted() {
    axios.defaults.baseURL = 'https://sekulictest.e-tournament.ch/api/'
    // if (!this.$store.getters.getCategoryId) {
    //   const response = await axios.get('json.php?get=categories')

    //   this.$store.dispatch('changeCategoryId', response.data[0].id_categorie)
    //   this.$store.dispatch('changeCategory', 0)
    // }
  },

  methods: {
    setMenu(menu) {
      this.menu = menu
    },
  },
}
</script>
