<template>
    <v-container class="mb-12">
      <v-alert
        elevation="6"
        type="success"
        v-if="success"
        dismissible
        @click:close="success = false"
      >Le score a bien été enrengistré.</v-alert>
      <v-card elevation="3" class="mt-5 py-6 px-2 text-center">
        <v-row class="header-row">
          <v-col cols="8">
            <p class="team--name">{{ teamOne.name }}</p>
          </v-col>
          <v-col cols="">
            <v-text-field
                v-model="scoreOne"
                type="number"
                placeholder="0"
                outlined
                class="score-input"
              ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="$store.getters.getIsReferee">
          <v-col cols="8" class="score-col">
            <p class="team--name">{{ teamTwo.name }}</p>
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="scoreTwo"
                type="number"
                placeholder="0"
                outlined
                class="score-input"
              ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="px-10" v-if="$store.getters.getIsReferee">
          <v-btn @click="handleScore" class="yellow darken-2 mx-auto" block large>Valider</v-btn>
        </v-row>
      </v-card>
    </v-container>
  </template>

  <script>
  import axios from 'axios';

  export default {
    name: 'Score',
    data() {
      return {
        matchId: null,
        teamOne: {},
        teamTwo: {},
        nField: null,
        time: null,
        scoreOne: null,
        scoreTwo: null,
        success: false,
      }
    },
    async mounted() {

      // Get match data (teams, field, etc...)
      this.matchId = this.$route.params.id;
      let res = await axios.get('https://sekulictest.e-tournament.ch/admin/api/v2/m/' + this.matchId)
        .then((res) => {
          this.nField = res.data.field;
          this.teamOne = {
            "name": res.data.team1,
            "tid": res.data.team1_id
          };
          this.teamTwo = {
            "name": res.data.team2,
            "tid": res.data.team2_id
          };
          this.time = res.data.time;
          this.time = this.time.slice(0, -3); // Remove seconds
          this.date = res.data.date;
        })
        .catch((err) => {
          console.error("MiNT Error: ", err);
        });

      this.$emit('set-title', 'Score');

      this.$emit('set-menu', {
        title: 'Score',
        link: '/fields',
        icon: 'mdi-close',
      });
    },
    methods: {
      async handleScore() {
        // Post score to backend by API
        let reqBody = {
          "team1": {
            "idt": this.teamOne.tid,
            "score": this.scoreOne
          },
          "team2": {
            "idt": this.teamTwo.tid,
            "score": this.scoreTwo
          }
        };
        let res = await axios.post('https://sekulictest.e-tournament.ch/admin/api/v2/4/m/' + this.matchId, reqBody, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((res) => {
            this.success = true;
          })
          .catch((err) => {
            console.error("MiNT Error: ", err);
          });
      }
    }
  }
  </script>

  <style lang="scss">
    .header-row {
      display: flex;
      align-items: center;
    }

    .team--name--scores {
      // background: red;
      height: 50%;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 500;
    }

    .vs--text {
      font-weight: bold;
      font-style: italic;
      font-size: 1.2rem;
    }

    .text-info {
      font-size: 1.2rem;
    }

    .score-input {
      min-width: 50px;
      font-weight: bold;
    }

    .score-col {
      display: flex;
      align-items: center;
      justify-content: center;
    }

  </style>
