import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
axios.defaults.baseURL = 'https://sekulictest.e-tournament.ch/api/'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedCategory: localStorage.category ? localStorage.category : 0,
    selectedCategoryId: localStorage.categoryId
      ? localStorage.categoryId
      : null,
    selectedTournoi: localStorage.tournoi
      ? localStorage.tournoi
      : null,
    selectedField: localStorage.field ? localStorage.field : 1,
    selectedTeam1: localStorage.team1 ? localStorage.team1 : null,
    selectedTeam2: localStorage.team2 ? localStorage.team2 : null,
    isReferee: localStorage.isReferee ? localStorage.isReferee : false,
  },
  getters: {
    getCategory(state) {
      return state.selectedCategory
    },
    getTournoi(state) {
      return state.selectedTournoi
    },
    getCategoryId(state) {
      return state.selectedCategoryId
    },
    getTeam1(state) {
      return state.selectedTeam1
    },
    getTeam2(state) {
      return state.selectedTeam2
    },
    getIsReferee(state) {
      return state.isReferee
    },
    getField(state) {
      return state.field
    }
  },
  mutations: {
    changeCategoryId(state, k) {
      state.selectedCategoryId = k
      localStorage.categoryId = k
    },
    changeCategory(state, categoryId) {
      state.selectedCategory = categoryId
      localStorage.category = categoryId
    },
    changeTournoi(state, tournoiId) {
      state.selectedTournoi = tournoiId
      localStorage.tournoi = tournoiId
    },
    changeTeam1(state, teamId) {
      state.selectedTeam1 = teamId
      localStorage.team1 = teamId
    },
    changeTeam2(state, teamId) {
      state.selectedTeam2 = teamId
      localStorage.team2 = teamId
    },
    setReferee(state, isReferee) {
      state.isReferee = isReferee
      localStorage.isReferee = isReferee
    },
    changeField(state, field) {
      state.field = field
      localStorage.field = field
    }
  },
  actions: {
    changeCategoryId(context, categoryId) {
      context.commit('changeCategoryId', categoryId)
    },
    changeCategory(context, categoryId) {
      context.commit('changeCategory', categoryId)
    },
    changeTournoi(context, tournoiId) {
      context.commit('changeTournoi', tournoiId)
    },
    changeTeam2(context, teamId) {
      context.commit('changeTeam2', teamId)
    },
    changeTeam1(context, teamId) {
      context.commit('changeTeam1', teamId)
    },
    setReferee(context, isReferee) {
      context.commit('setReferee', isReferee)
    },
    changeField(context, field) {
      context.commit('changeField', field)
    }
  },
  modules: {},
})
